import type { Video } from '@design-system/components/UI/UIMedia.props'
import { joinURL } from 'ufo'

/**
 * Get the full video URL.
 *
 * This function constructs the full URL for the video source. It supports both
 * absolute URLs (starting with 'http') and relative URLs by joining them with
 * the base Cloudinary URL from runtime config.
 *
 * @param {string} src - The source of the video (either absolute or relative).
 *
 * @returns {string} The full URL of the video.
 */
export const getVideo = (src: string): string => {
  // Support runtime config for base Cloudinary URL
  const baseURL: string = useRuntimeConfig().public.cloudinary
  return src.startsWith('http') ? src : joinURL(baseURL, src)
}

/**
 * Get the optimized video source URL.
 *
 * This function optimizes the video URL using Cloudinary transformations.
 *
 * @param {Video} video - The video object containing the source URL.
 * @param {string} format - The desired video format (e.g., 'mp4', 'webm').
 * @param {string} [aspectRatio] - Optional aspect ratio for video optimization.
 *
 * @returns {string} The optimized video URL.
 */
export const getVideoScr = (
  video: Video,
  format: string,
  aspectRatio?: string
): string =>
  optimizeVideo(
    getVideo(video?.srcVideo?.public_id ?? video?.srcVideo?.secure_url ?? '#'),
    video.quality ?? 'best',
    aspectRatio,
    format
  )

/**
 * Format time from seconds to mm:ss format.
 *
 * This function takes a number of seconds and formats it into a more human-readable
 * "minutes:seconds" format (e.g., 90 seconds becomes '1:30').
 *
 * @param {number} seconds - The time in seconds.
 *
 * @returns {string} The formatted time in mm:ss.
 */
export const formatTime = (seconds: number): string => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${mins}:${secs < 10 ? '0' : ''}${secs}` // Pad single-digit seconds with a zero
}
